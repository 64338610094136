import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material/material.module';
import { HttpClientModule } from '@angular/common/http';
import { CovidOhModule } from './featured-modules/covid-oh/covid-oh.module';
import { ValidateCardPipe } from './shared/pipes/validateCard/validate-card.pipe';
import { TitleCasePipe } from './shared/pipes/titleCase/title-case.pipe';
import { PhonePipe } from './shared/pipes/validation/phone.pipe';
import { ValidateMYPipe } from './shared/pipes/validate-my.pipe';
import { DateCasePipePipe } from './shared/pipes/dateCasePipe/date-case-pipe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    ValidateCardPipe,
    TitleCasePipe,
    PhonePipe,
    ValidateMYPipe,
    DateCasePipePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    CovidOhModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
