import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    // // console.log(value.substr(0,3));

    let phone = value.split(' ');

    if (phone.length === 2) {
      return phone[1];
    } else {
      return value;
    }
  }
}
