import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../utils/global';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private http: HttpClient) {}

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'POST, OPTIONS',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': '*',
  });

  listUser(dni: string) {
    const url_api = `${Globals.devUrl}/listUser/${dni}`;
    return this.http
      .get(url_api, { headers: this.headers })
      .pipe(map((data) => data));
  }

  listUbigeo(dni: string) {
    const url_api = `${Globals.devUrl}/listUbigeo/${dni}`;
    return this.http
      .get(url_api, { headers: this.headers })
      .pipe(map((data) => data));
  }

  listDepartamentos() {
    const url_api = `${Globals.devUrl}/departamentos-lima`;
    return this.http
      .get(url_api, { headers: this.headers })
      .pipe(map((data) => data));
  }

  listProvincias(idDepartamento: string) {
    const url_api = `${Globals.devUrl}/provincias-lima/${idDepartamento}`;
    return this.http
      .get(url_api, { headers: this.headers })
      .pipe(map((data) => data));
  }

  listDistritos(idProvincia: string) {
    const url_api = `${Globals.devUrl}/distritos/${idProvincia}`;
    return this.http
      .get(url_api, { headers: this.headers })
      .pipe(map((data) => data));
  }

  setPrice(precio: number) {
    sessionStorage.setItem('precio', precio.toString());
  }

  getPrice(): number {
    return parseInt(sessionStorage.getItem('precio'));
  }
}