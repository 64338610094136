import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-payment',
  templateUrl: './resume-payment.component.html',
  styleUrls: ['./resume-payment.component.scss'],
})
export class ResumePaymentComponent implements OnInit {
  @Input() item: number;
  public precioInicial = 130;

  public precioSub = 0;
  public precioIgv = 0;

  constructor() {}

  ngOnInit(): void {
    this.precioIgv = this.precioInicial * 0.18 * this.item;
    this.precioSub = this.precioInicial * 0.82 * this.item;
  }
}
