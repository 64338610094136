import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { Ubigeo, User } from '../../models/user.model';
import { UtilService } from '../../services/utils/utils.service';
import { ModalUbigeoComponent } from '../modal-ubigeo/modal-ubigeo.component';

@Component({
  selector: 'app-modal-add-afiliado',
  templateUrl: './modal-add-afiliado.component.html',
  styleUrls: ['./modal-add-afiliado.component.scss'],
})
export class ModalAddAfiliadoComponent implements OnInit {
  public precio: number;

  afiliado: User = {
    user_id: 0,
    user_tipoDoc_id: 2,
    user_numDoc: '',
    user_nom1: '',
    user_nom2: '',
    user_ape1: '',
    user_ape2: '',
    user_direcc: '',
    user_telf: '',
    user_email: '',
    user_ubg: '',
    user_fech_nac: '',
    user_sexo: '',
    user_tipo: '',
    user_plan: 0,
    user_ideprovedor: 0,
  };

  ubigeo: Ubigeo = {
    id: '',
    departamento: '',
    provincia: '',
    distrito: '',
  };

  ubigeoString: string;

  formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalAddAfiliadoComponent>,
    public dialog: MatDialog,
    private service: UtilService,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: User[]
  ) {}

  ngOnInit(): void {
    this.precio = this.service.getPrice();

    this.formGroup = this._formBuilder.group({
      dni: ['', Validators.required],
      telefono: ['', Validators.required],
      email: ['', Validators.required],
      nomb1: ['', Validators.required],
      ape1: ['', Validators.required],
      ape2: ['', Validators.required],
      direccion: ['', Validators.required],
      ubigeo: ['', Validators.required],
    });
  }

  modal() {
    const dialogRef = this.dialog.open(ModalUbigeoComponent, {
      width: '80%',
      data: this.ubigeo,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.ubigeo = result;
      // if (this.ubigeo.departamento === '') {
      this.ubigeoString = `${this.ubigeo.departamento}/${this.ubigeo.provincia}/${this.ubigeo.distrito}`;
      this.afiliado.user_ubg = this.ubigeo.id;
      // }
      // console.log(this.ubigeo);
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  aea() {
    if (
      this.afiliado.user_numDoc == '' ||
      this.afiliado.user_nom1 == '' ||
      this.afiliado.user_ape1 == '' ||
      this.afiliado.user_ape2 == '' ||
      this.afiliado.user_direcc == '' ||
      this.afiliado.user_ubg == ''
    ) {
      // alert('Completa todos los datos necesarios uwu');
    } else {
      // this.afiliado.user_plan = this.precio == 55 ? 100 : 99;
      (this.afiliado.user_plan = this.precio == 55 ? 256 : 255),
        (this.afiliado.user_tipo = '2');
      this.data.push(this.afiliado);
      this.dialogRef.close();
    }
  }
}
