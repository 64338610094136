<div class="resumen">
  <h2>Resumen de la Orden</h2>
  <div class="compra">
    <img src="{{ icon }}" alt="" />
    <div class="compra-plan">
      <h4>Plan {{ plan == "familiar" ? "Nosotros" : "Otros" }}</h4>
      <p>
        Precio: <span> S/ {{ total }} </span>
      </p>
      <p>Afiliados: {{ q }}</p>
    </div>
  </div>
  <div class="compra-text">
    <p>Sub Total</p>
    <p>S/ {{ total * 0.82 * q | number: "1.2-2" }}</p>
  </div>
  <div class="compra-text">
    <p>IGV</p>
    <p>S/ {{ total * 0.18 * q | number: "1.2-2" }}</p>
  </div>
  <div class="compra-total">
    Orden Total:&nbsp;<span>S/ {{ total * q }}</span>
  </div>
</div>
