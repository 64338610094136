import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { Ubigeo, User } from '../../models/user.model';
import { ModalUbigeoComponent } from '../modal-ubigeo/modal-ubigeo.component';

@Component({
  selector: 'app-modal-edit-afiliado',
  templateUrl: './modal-edit-afiliado.component.html',
  styleUrls: ['./modal-edit-afiliado.component.scss'],
})
export class ModalEditAfiliadoComponent implements OnInit {
  afiliado: User = {
    user_id: 0,
    user_tipoDoc_id: 2,
    user_numDoc: '',
    user_nom1: '',
    user_nom2: '',
    user_ape1: '',
    user_ape2: '',
    user_direcc: '',
    user_telf: '',
    user_email: '',
    user_ubg: '',
    user_fech_nac: '',
    user_sexo: '',
    user_tipo: '',
    user_plan: 0,
    user_ideprovedor: 0,
  };

  ubigeo: Ubigeo = {
    id: '',
    departamento: '',
    provincia: '',
    distrito: '',
  };

  ubigeoString: string;

  formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalEditAfiliadoComponent>,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.afiliado.user_fech_nac = new Date().toLocaleDateString();
    // console.log(this.afiliado.user_fech_nac);

    this.ubigeoString = this.data['ubigeo'];

    this.afiliado = this.data['user'];
    this.formGroup = this._formBuilder.group({
      dni: ['', Validators.required],
      telefono: ['', Validators.required],
      email: ['', Validators.required],
      nomb1: ['', Validators.required],
      ape1: ['', Validators.required],
      ape2: ['', Validators.required],
      direccion: ['', Validators.required],
      ubigeo: ['', Validators.required],
    });
  }

  modal() {
    const dialogRef = this.dialog.open(ModalUbigeoComponent, {
      width: '80%',
      data: this.ubigeo,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.ubigeo = result;
      this.ubigeoString = `${this.ubigeo.departamento}/${this.ubigeo.provincia}/${this.ubigeo.distrito}`;
      this.afiliado.user_ubg = this.ubigeo.id;
      // console.log(this.ubigeo);
    });
  }

  close(): void {
    this.dialogRef.close({ data: this.data['user'] });
  }

  date() {
    let fecha = new Date(this.afiliado.user_fech_nac);
    this.afiliado.user_fech_nac = this.formatDate(fecha);
    // console.log(this.afiliado.user_fech_nac);
  }

  formatDate(date) {
    let day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    let year = date.getFullYear();
    return year + month + day;
  }

  aea() {
    if (
      this.afiliado.user_numDoc == '' ||
      this.afiliado.user_nom1 == '' ||
      this.afiliado.user_ape1 == '' ||
      this.afiliado.user_ape2 == '' ||
      this.afiliado.user_direcc == '' ||
      this.afiliado.user_ubg == ''
    ) {
      alert('Completa todos los datos necesarios uwu');
    } else {
      this.dialogRef.close({ data: this.afiliado });
    }
  }
}
