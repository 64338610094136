import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CovidOhRoutingModule } from './covid-oh-routing.module';
import { CheckoutPaymentComponent } from './components/checkout-payment/checkout-payment.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { LandingComponent } from './components/landing/landing.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CheckoutPaymentComponent, LandingComponent],
  imports: [
    CommonModule,
    CovidOhRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
})
export class CovidOhModule {}
