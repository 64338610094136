import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validateMY',
})
export class ValidateMYPipe implements PipeTransform {
  transform(key: string): boolean {
    const mmyyyy = new RegExp('^((0[1-9])|(1[0-2]))/((2020)|(20[2-3][0-9]))$');

    if (mmyyyy.test(key)) {
      return true;
    }
    return false;
  }
}
