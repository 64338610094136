import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogComponent } from '../../material/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog, private router: Router) {}

  public dialogRef;

  material(message: string, page: string) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      height: 'auto',
      width:
        message == 'thanks'
          ? '40rem'
          : 'envio' || 'poliza' || 'renovacion'
          ? '30rem'
          : 'auto',
      data: { message: message, page: page },
    });

    this.dialogRef.afterOpened().subscribe((_) => {
      if (message === 'thanks') {
        setTimeout(() => {
          this.close();
          this.router.navigate(['']);
        }, 8000);
      } else if (message === 'error') {
        setTimeout(() => {
          this.close();
          this.router.navigate(['']);
        }, 2500);
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
}