  
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateCasePipe',
})
export class DateCasePipePipe implements PipeTransform {
  transform(value: string): string {
    let anio = value.substr(0, 4);
    let mes = value.substr(4, 2);
    let dia = value.substr(6, 2);

    let fecha = `${mes}/${dia}/${anio}`;

    return fecha;
  }
}