import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from 'src/app/shared/services/utils/utils.service';

@Component({
  selector: 'app-resumen-compra-plan',
  templateUrl: './resumen-compra-plan.component.html',
  styleUrls: ['./resumen-compra-plan.component.scss'],
})
export class ResumenCompraPlanComponent implements OnInit {
  @Input() public plan: string;
  @Input() public q: number;

  public icon: string = '';
  public total;
  public subTotal = 0;
  public igv = 0;

  constructor(private service: UtilService) {}

  ngOnInit(): void {
    this.total = this.service.getPrice();

    if (this.plan === 'familiar') {
      this.icon = 'assets/icons/familia.svg';
    } else {
      this.icon = 'assets/icons/otros.svg';
    }
  }
}
