<mat-accordion *ngIf="page == 'planes'">
  <mat-expansion-panel *ngFor="let pregunta of preguntasPlanes">
    <mat-expansion-panel-header>
      <mat-panel-title> {{ pregunta.pregunta }} </mat-panel-title>
    </mat-expansion-panel-header>
    <p>{{ pregunta.respuesta }}</p>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="page == 'test-covid'">
  <mat-expansion-panel *ngFor="let pregunta of preguntasCovid">
    <mat-expansion-panel-header>
      <mat-panel-title> {{ pregunta.pregunta }} </mat-panel-title>
    </mat-expansion-panel-header>
    <p>{{ pregunta.respuesta }}</p>
  </mat-expansion-panel>
</mat-accordion>
