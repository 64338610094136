<div class="carga" *ngIf="loading">
  <div class="style-100">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div class="principal">
  <mat-horizontal-stepper [linear]="true" labelPosition="bottom" #stepper>
    <!-- <mat-horizontal-stepper labelPosition="bottom" #stepper> -->
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>
          <h2 class="m-0 h2">1</h2>
          <h3 class="m-0 h3">Datos</h3>
        </ng-template>
        <div class="container">
          <div class="container__first">
            <h2 class="title mobile">Resumen de Compra</h2>
            <app-resume-payment [item]="datoInicial"></app-resume-payment>
          </div>
          <div class="detalle">
            <div class="container__second">
              <h2 class="title mobile">Datos del contratante</h2>
              <div class="outlined-sec">
                <h1 class="web">Contratante</h1>
                <h1 class="web"></h1>
                <div class="outlined-sec--item">
                  <mat-label>Tipo de documento</mat-label>
                  <mat-form-field
                    appearance="outline"
                    [(ngModel)]="dataPrincipal.type"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="onChange($event)"
                    ngDefaultControl
                  >
                    <select formControlName="type" matNativeControl>
                      <option value="" selected>Seleccione</option>
                      <option value="2">DNI</option>
                      <option value="4">Pasaporte</option>
                      <option value="1">RUC</option>
                      <option value="3">Carnet de extranjería</option>
                      <option value="5">Cédula de identificación</option>
                    </select>
                    <mat-error *ngIf="dataPrincipal.type === ''"
                      >Por favor completa este campo</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>Número de documento</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="number"
                      type="tel"
                      maxlength="{{ maxLengthType }}"
                      [(ngModel)]="dataPrincipal.number"
                      (ngModelChange)="onChange($event)"
                      required
                    />

                    <div class="search" *ngIf="searchType" matSuffix>
                      <div class="lds-dual-ring"></div>
                    </div>

                    <mat-icon matSuffix *ngIf="!searchType"
                      >assignment_ind</mat-icon
                    >

                    <mat-error
                      *ngIf="
                        firstFormGroup.controls.number.hasError('required')
                      "
                      >Por favor completa este campo
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>Primer Nombre</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="first_name"
                      type="text"
                      [(ngModel)]="dataPrincipal.first_name"
                      ngDefaultControl
                      required
                    />
                    <mat-icon matSuffix>account_circle</mat-icon>

                    <mat-error
                      *ngIf="
                        firstFormGroup.controls.first_name.hasError('required')
                      "
                      >Por favor completa este campo
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>Segundo Nombre</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      type="text"
                      [(ngModel)]="dataPrincipal.seconds_name"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <mat-icon matSuffix>account_circle</mat-icon>
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>Primer Apellido</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="first_lastname"
                      type="text"
                      [(ngModel)]="dataPrincipal.first_lastname"
                      required
                    />
                    <mat-icon matSuffix>account_circle</mat-icon>
                    <mat-error
                      *ngIf="
                        firstFormGroup.controls.first_lastname.hasError(
                          'required'
                        )
                      "
                      >Por favor completa este campo
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>Segundo Apellido</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="second_lastname"
                      type="text"
                      [(ngModel)]="dataPrincipal.second_lastname"
                      required
                    />
                    <mat-icon matSuffix>account_circle</mat-icon>
                    <mat-error
                      *ngIf="
                        firstFormGroup.controls.second_lastname.hasError(
                          'required'
                        )
                      "
                      >Por favor completa este campo
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="botones">
              <button mat-button class="red-button-text" (click)="back()">
                VOLVER
              </button>
              <button
                mat-raised-button
                color="warn"
                class="red-button"
                [disabled]="firstValidator() === false"
                matStepperNext
              >
                SIGUIENTE
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>
          <h2 class="m-0">2</h2>
          <h3 class="m-0">Detalle</h3>
        </ng-template>
        <div class="container">
          <div class="container__first">
            <h2 class="title mobile">Resumen de Compra</h2>
            <app-resume-payment [item]="datoInicial"></app-resume-payment>
          </div>
          <div class="detalle">
            <div class="container__second">
              <h2 class="title mobile">Datos del contratante</h2>
              <div class="outlined-sec">
                <h1 class="web">Contratante</h1>
                <h1 class="web"></h1>
                <div class="outlined-sec--item">
                  <mat-label>Correo</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="mail"
                      type="email"
                      [(ngModel)]="dataSecondary.mail"
                      required
                    />
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf="dataSecondary.mail === ''"
                      >Por favor completa este campo
                    </mat-error>
                    <mat-error
                      *ngIf="
                        !secondFormGroup.controls.mail.hasError('required') &&
                        secondFormGroup.controls.mail.hasError('email')
                      "
                    >
                      Ingresa un correo válido
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>Télefono</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="phone"
                      type="tel"
                      maxlength="9"
                      [(ngModel)]="dataSecondary.phone"
                      required
                    />
                    <mat-icon matSuffix>phone</mat-icon>
                    <mat-error
                      *ngIf="
                        secondFormGroup.controls.phone.hasError('required')
                      "
                      >Por favor completa este campo
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>Ubicación</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="ubication"
                      type="text"
                      [(ngModel)]="dataSecondary.general"
                      (click)="selectUbigeo()"
                      readonly
                      required
                    />
                    <mat-icon matSuffix>location_on</mat-icon>
                    <mat-error
                      *ngIf="
                        secondFormGroup.controls.ubication.hasError('required')
                      "
                      >Por favor completa este campo
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>Dirección</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="direction"
                      type="text"
                      [(ngModel)]="dataSecondary.direction"
                      required
                    />
                    <mat-error
                      *ngIf="
                        secondFormGroup.controls.direction.hasError('required')
                      "
                      >Por favor completa este campo
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>¿Donde se realizará la atención?</mat-label>
                  <mat-radio-button
                    (change)="onChangeButton(true)"
                    [color]="'warn'"
                    >En un laboratorio</mat-radio-button
                  >

                  <mat-form-field
                    *ngIf="showCenter"
                    appearance="outline"
                    [(ngModel)]="dataSecondary.atention_center"
                    [ngModelOptions]="{ standalone: true }"
                    ngDefaultControl
                    (ngModelChange)="onChangeSelect($event)"
                  >
                    <select matNativeControl>
                      <!-- <option value="1" ojo aquí gaaaa selected> -->
                      <option value="0" selected>Seleccione</option>
                      <option value="345">
                        Calle Francisco Redi 100, San Borja
                      </option>
                      <option value="347">
                        Calle San Aurelio 865, San Juan de Lurigancho
                      </option>
                    </select>
                  </mat-form-field>

                  <mat-radio-button
                    (change)="onChangeButton(false)"
                    [color]="'warn'"
                    >En mi domicilio</mat-radio-button
                  >
                </div>
                <div class="outlined-sec--item">
                  <mat-label>¿Usted se va a hacer la prueba?</mat-label>
                  <mat-radio-button
                    [name]="'primer_paciente'"
                    (change)="addPerson(1)"
                    [color]="'warn'"
                    >Si</mat-radio-button
                  >

                  <mat-radio-button
                    [name]="'primer_paciente'"
                    (change)="addPerson(0)"
                    [color]="'warn'"
                    >No</mat-radio-button
                  >
                </div>
              </div>
            </div>
            <div class="botones">
              <button mat-button matStepperPrevious class="red-button-text">
                VOLVER
              </button>
              <button
                mat-raised-button
                color="warn"
                class="red-button"
                [disabled]="secondValidator() === false"
                (click)="editAfiliacion(1)"
                matStepperNext
              >
                SIGUIENTE
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup">
      <form [formGroup]="thirdFormGroup">
        <ng-template matStepLabel>
          <h2 class="m-0">3</h2>
          <h3 class="m-0">Pacientes</h3></ng-template
        >
        <div class="container">
          <div class="container__first">
            <h2 class="title mobile">Resumen de Compra</h2>
            <app-resume-payment [item]="datoInicial"></app-resume-payment>
          </div>

          <div class="container__second">
            <div class="row mobile paciente--mobile">
              <h2 class="title">Pacientes</h2>
              <button mat-mini-fab (click)="addAfiliado()" color="accent">
                <mat-icon>plus_one</mat-icon>
              </button>
            </div>
            <div class="outlined-sec2">
              <div class="row web paciente--web">
                <h1>Pacientes</h1>
                <button mat-mini-fab (click)="addAfiliado()" color="accent">
                  <mat-icon>plus_one</mat-icon>
                </button>
              </div>
              <h1 class="web"></h1>
              <div class="hijo">
                <mat-accordion *ngFor="let i of dataAfiliados">
                  <mat-expansion-panel
                    [expanded]="step === i.user_id"
                    (opened)="setStep(i.user_id)"
                    [disabled]="
                      i.user_email !== '' &&
                      i.user_numDoc !== null &&
                      i.user_fech_nac !== ''
                        ? true
                        : false
                    "
                    hideToggle
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title *ngIf="i.user_email === ''">
                        <h4>Paciente {{ i.user_id }}</h4>
                        <div class="row">
                          <h3>+ Agregar datos Paciente {{ i.user_id }}</h3>
                          <mat-icon
                            (click)="deleteAfiliacion(i.user_id)"
                            *ngIf="datoInicial !== 1"
                            >delete</mat-icon
                          >
                        </div>
                      </mat-panel-title>

                      <mat-panel-title *ngIf="i.user_email !== ''">
                        <h4>Paciente {{ i.user_id }}</h4>
                        <div class="row">
                          <h3>{{ i.user_nom1 }}</h3>
                          <div class="row-simple">
                            <mat-icon
                              (click)="deleteAfiliacion(i.user_id)"
                              *ngIf="datoInicial !== 1"
                              >delete</mat-icon
                            >
                            <mat-icon (click)="editAfiliacion(i.user_id)"
                              >edit</mat-icon
                            >
                          </div>
                        </div>
                        <h5>{{ i.user_numDoc }}</h5>
                        <h5>{{ i.user_email }}</h5>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-form-add-pacient
                      [initial]="i"
                      (resultado)="resultAfiliacion($event)"
                      (back)="cancelAfiliacion($event)"
                    ></app-form-add-pacient>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
            <div class="botones">
              <p></p>
              <div class="row-simple">
                <button mat-button matStepperPrevious class="red-button-text">
                  VOLVER
                </button>
                <button
                  mat-raised-button
                  color="warn"
                  class="red-button"
                  [disabled]="thirdValidator() === false"
                  matStepperNext
                >
                  SIGUIENTE
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="fourFormGroup">
      <form [formGroup]="fourFormGroup">
        <ng-template matStepLabel>
          <h2 class="m-0">4</h2>
          <h3 class="m-0">Pagar</h3></ng-template
        >
        <div class="container">
          <div class="container__first">
            <h2 class="title mobile">Resumen de Compra</h2>
            <div class="column">
              <app-resume-payment [item]="datoInicial"></app-resume-payment>
              <!-- <div class="row-simple">
                <img src="assets/images/Imagen 28.svg" alt="norton" />
                <img src="assets/images/Imagen 29.svg" alt="mcafee" />
                <mat-icon>lock</mat-icon>
              </div> -->
            </div>
          </div>
          <div class="detalle">
            <h2 class="title mobile">Método de pago</h2>
            <div class="container__second">
              <div class="outlined-sec">
                <h1 class="web">Pagar</h1>
                <div class="flex-end">
                  <img src="assets/images/g10.svg" alt="payu" />
                </div>

                <div class="outlined-sec--item">
                  <mat-label
                    >Número de tarjeta
                    <img src="assets/images/visa.svg" alt="visa" />
                    <img src="assets/images/Grupo 567.svg" alt="mastercard" />
                    <img
                      src="assets/images/american-express.svg"
                      alt="american-express"
                    />
                    <img
                      src="assets/images/diners-club.svg"
                      alt="dinners-club"
                    />
                  </mat-label>
                  <mat-form-field
                    appearance="outline"
                    [(ngModel)]="creditCard.number_credit"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="getTypeCreditCard()"
                    ngDefaultControl
                  >
                    <input
                      matInput
                      formControlName="numbercredit"
                      type="tel"
                      required
                      autocomplete="off"
                    />

                    <mat-error
                      *ngIf="
                        fourFormGroup.controls.numbercredit.hasError('required')
                      "
                      >Por favor completa este campo</mat-error
                    >

                    <span [ngSwitch]="creditCard.type" matSuffix>
                      <mat-icon *ngSwitchCase="'VISA'">
                        <img src="assets/images/visa.svg" alt="visa" />
                      </mat-icon>
                      <mat-icon *ngSwitchCase="'MASTERCARD'">
                        <img
                          src="assets/images/Grupo 567.svg"
                          alt="mastercard"
                        />
                      </mat-icon>
                      <mat-icon *ngSwitchCase="'AMEX'">
                        <img
                          src="assets/images/american-express.svg"
                          alt="american"
                        />
                      </mat-icon>
                      <mat-icon *ngSwitchCase="'DINNERS'">
                        <img
                          src="assets/images/diners-club.svg"
                          alt="dinners"
                        />
                      </mat-icon>
                      <mat-icon *ngSwitchDefault>
                        account_balance_wallet
                      </mat-icon>
                    </span>
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>Nombre completo</mat-label>
                  <mat-form-field
                    appearance="outline"
                    [(ngModel)]="creditCard.fullname"
                    [ngModelOptions]="{ standalone: true }"
                    ngDefaultControl
                  >
                    <input
                      matInput
                      formControlName="fullname"
                      type="text"
                      required
                    />
                    <mat-icon matSuffix>account_circle</mat-icon>
                    <mat-error
                      *ngIf="
                        fourFormGroup.controls.fullname.hasError('required')
                      "
                      >Por favor completa este campo</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>Fecha de expiración (mm-YY)</mat-label>
                  <mat-form-field
                    appearance="outline"
                    [(ngModel)]="creditCard.expiration"
                    [ngModelOptions]="{ standalone: true }"
                    (ngModelChange)="validateMMYY()"
                    ngDefaultControl
                  >
                    <input
                      matInput
                      [(ngModel)]="creditCard.expiration"
                      (ngModelChange)="validateMMYY()"
                      formControlName="expiration"
                      placeholder="09/25"
                      type="text"
                      maxlength="5"
                      autocomplete="off"
                      required
                    />
                    <mat-icon matSuffix>today</mat-icon>
                    <mat-error
                      *ngIf="
                        fourFormGroup.controls.expiration.hasError('required')
                      "
                      >Por favor completa este campo</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="outlined-sec--item">
                  <mat-label>CVV</mat-label>
                  <mat-form-field
                    appearance="outline"
                    [(ngModel)]="creditCard.cvv"
                    [ngModelOptions]="{ standalone: true }"
                    ngDefaultControl
                  >
                    <input
                      matInput
                      formControlName="cvv"
                      type="tel"
                      maxlength="4"
                      required
                      autocomplete="off"
                    />
                    <mat-icon
                      matTooltip="Son los tres dígitos que está al reverso de la tarjeta"
                      matSuffix
                      >info</mat-icon
                    >

                    <mat-error
                      *ngIf="fourFormGroup.controls.cvv.hasError('required')"
                      >Por favor completa este campo</mat-error
                    >
                  </mat-form-field>
                </div>
                <div>
                  <mat-checkbox
                    (click)="changeCheckValue('envio')"
                  ></mat-checkbox>
                  Acepto las Políticas de
                  <span (click)="conditions('envio')"
                    >envío de comunicaciones comerciales.</span
                  >
                </div>
                <div>
                  <mat-checkbox (click)="changeCheckValue('poliza')">
                  </mat-checkbox>

                  Acepto la Política de
                  <span (click)="conditions('poliza')"
                    >envío de la póliza de seguro.</span
                  >
                </div>
              </div>
            </div>
            <div class="botones">
              <button mat-button matStepperPrevious class="red-button-text">
                VOLVER
              </button>
              <button
                mat-raised-button
                color="warn"
                class="red-button"
                (click)="fourClick()"
                [disabled]="fourValidator() === false"
              >
                PAGAR
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>