<section>
  <div class="web column-simple">
    <h2>Pruebas Antígenas</h2>
    <div class="row">
      <div class="row-simple">
        <h4>Precio Unitario:</h4>
        <h4 class="red">S/ 130.00</h4>
      </div>
      <div class="row-simple">
        <h4>Unidades:</h4>
        <h4 class="red">{{ item }}</h4>
      </div>
    </div>
  </div>
  <div class="mobile">
    <h1>Resumen de la orden</h1>
  </div>
  <hr class="mobile" />

  <div class="row-simple mobile">
    <img src="assets/images/coronavirus.svg" alt="covid" />
    <div class="column">
      <div class="column-simple h65">
        <h2>Pruebas Antígenas</h2>
        <div class="row-simple">
          <h4>Precio unitario:</h4>
          <h4 class="red">S/ 130.00</h4>
        </div>
        <div class="row-simple">
          <h4>Unidades:</h4>
          <h4 class="red">{{ item }}</h4>
        </div>
      </div>
    </div>
  </div>

  <hr />
  <div class="row">
    <h4>Subtotal</h4>
    <h4>S/ {{ item * 130 / 1.18 | number: "1.2-2" }}</h4>
  </div>
  <div class="row">
    <h4>IGV</h4>
    <h4>S/ {{ item * (130 / 1.18)*0.18 | number: "1.2-2" }}</h4>
  </div>
  <div class="center">
    <div class="false-button row-simple">
      <h4>Orden total:</h4>
      <h4 class="red">S/ {{ item * 130 | number: "1.2-2" }}</h4>
    </div>
  </div>
</section>
