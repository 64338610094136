<p>
  <mat-toolbar color="primary">
    <span></span>
    <span class="spacer"></span>
    <a href="https://www.facebook.com/RedSaludPeru" target="_blank">
      <button mat-icon-button class="example-icon">
        <img src="assets/icons/facebook.svg" alt="facebook" />
      </button>
    </a>
    <a href="https://www.instagram.com/red_salud/?hl=es-la" target="_blank">
      <button mat-icon-button class="example-icon">
        <img src="assets/icons/instagram.svg" alt="instagram" />
      </button>
    </a>
    <a
      href="https://www.linkedin.com/company/redsalud-contigosalud"
      target="_blank"
    >
      <button mat-icon-button class="example-icon">
        <img src="assets/icons/linkedin.svg" alt="lnkedin" />
      </button>
    </a>

    <a
      href="https://www.youtube.com/channel/UCla1nND5UE8NR6G7iroAWog?view_as=subscriber"
      target="_blank"
    >
      <button mat-icon-button class="example-icon">
        <img src="assets/icons/youtube.svg" alt="youtube" />
      </button>
    </a>
  </mat-toolbar>
</p>

<router-outlet></router-outlet>

<footer>
  <div class="oh">
    <img src="/assets/icons/oh-logo.svg" alt="" />
    <h2>Beneficio Exclusivo con Tarjeta Oh!</h2>
  </div>
  <div class="contacto">
    <img src="/assets/png/red-salud-logo.png" alt="" />
    <div class="contacto-child">
      <img class="icon" src="/assets/icons/calendar.svg" alt="" />
      <p>Lunes - Viernes 9am a 6pm</p>
    </div>
    <div class="contacto-child">
      <img class="icon" src="/assets/icons/phone.svg" alt="" />
      <p>Lima : (01) 445-3019 opción 2</p>
    </div>
    <div class="contacto-child">
      <img class="icon" src="/assets/icons/mail.svg" alt="" />
      <p>afiliaciones1@red-salud.com</p>
    </div>
  </div>
  <div class="info">
    <h2>Dirección:</h2>
    <p>Av. Jorge Chávez 263, Of. 303, Miraflores</p>
    <h2>Síguenos:</h2>
    <div class="redes">
      <a href="https://www.facebook.com/RedSaludPeru" target="_blank"
        ><img src="/assets/icons/icon-facebook.svg" alt=""
      /></a>
      <a href="https://www.instagram.com/red_salud/?hl=es-la" target="_blank"
        ><img src="/assets/images/instagram.svg" alt="instagram"
      /></a>
      <a
        href="https://www.youtube.com/channel/UCla1nND5UE8NR6G7iroAWog"
        target="_blank"
        ><img src="/assets/icons/icon-youtube.svg" alt=""
      /></a>
      <a
        href="https://www.linkedin.com/company/redsalud-contigosalud/"
        target="_blank"
        ><img src="/assets/icons/icon-linkedin.svg" alt=""
      /></a>
    </div>
  </div>
</footer>
