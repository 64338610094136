import { Pagos } from './../../../../shared/models/payment-model';
import {
  AseguradoFinal,
  DocumentPayment,
  DocumentPrincipal,
  DocumentSecondary,
} from './../../../../shared/models/asegurado-model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AseguradoModel } from 'src/app/shared/models/asegurado-model';
import { ValidateCardPipe } from 'src/app/shared/pipes/validateCard/validate-card.pipe';
import { CovidService } from 'src/app/shared/services/covid/covid.service';
import { UtilService } from 'src/app/shared/services/utils/utils.service';
import { TitleCasePipe } from '@angular/common';
import { PhonePipe } from 'src/app/shared/pipes/validation/phone.pipe';
import { ModalUbigeoComponent } from 'src/app/shared/material/modal-ubigeo/modal-ubigeo.component';
import { MatDialog } from '@angular/material/dialog';
import { Ubigeo } from 'src/app/shared/models/user.model';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { DateCasePipePipe } from 'src/app/shared/pipes/dateCasePipe/date-case-pipe.pipe';
@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.scss'],
})
export class CheckoutPaymentComponent implements OnInit {
  public datoInicial: number = 0;
  public loading: boolean = false;
  public step: number = 0;
  public ubigeo_oficial: string;

  public envio: boolean = false;
  public datos: boolean = false;
  public poliza: boolean = false;
  public renovacion: boolean = false;

  public maxLengthType: number = 20;
  public searchType: Boolean = false;
  public dataPrincipal: DocumentPrincipal = {
    id: 0,
    type: '',
    number: null,
    first_name: '',
    seconds_name: '',
    first_lastname: '',
    second_lastname: '',
  };

  public showCenter: Boolean = false;
  public dataSecondary: DocumentSecondary = {
    mail: '',
    phone: null,
    general: '',
    direction: '',
    atention_center: 0,
    nacimiento: '',
  };
  public ubigeo: Ubigeo = {
    id: '',
    departamento: '',
    provincia: '',
    distrito: '',
  };

  public dataAfiliados: Array<AseguradoModel> = [];

  public creditCard: DocumentPayment = {
    number_credit: null,
    type: '',
    fullname: '',
    expiration: '',
    cvv: null,
  };

  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public fourFormGroup: FormGroup;
  public addQuestion: number;
  public validatorPlan: number;

  constructor(
    private formBuilder: FormBuilder,
    private covidService: CovidService,
    private utilService: UtilService,
    private paymentService: PaymentService,
    public dialog: MatDialog,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.datoInicial = this.covidService.getCant();
    for (let i = 0; i < this.datoInicial; i++) {
      this.dataAfiliados.push({
        id: 0,
        user_id: i + 1,
        user_tipo_doc: '',
        user_numDoc: null,
        user_nom1: '',
        user_ape1: '',
        user_ape2: '',
        user_fech_nac: '',
        user_email: '',
        user_plan: null,
      });
    }
    this.firstFormGroup = this.formBuilder.group({
      type: ['', Validators.required],
      number: ['', Validators.required],
      first_name: ['', Validators.required],
      first_lastname: ['', Validators.required],
      second_lastname: ['', Validators.required],
    });
    this.secondFormGroup = this.formBuilder.group({
      mail: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      ubication: ['', Validators.required],
      direction: ['', Validators.required],
    });
    this.thirdFormGroup = this.formBuilder.group({});
    this.fourFormGroup = this.formBuilder.group({
      numbercredit: ['', Validators.required],
      fullname: ['', Validators.required],
      expiration: ['', Validators.required],
      cvv: ['', Validators.required],
    });
  }

  fourClick(): void {
    this.dialogService.material('loading', 'seguros');
    let pagos: Pagos = {
      codplan: `Pruebas-Covid-Oh-${this.dataAfiliados.length}`,
      email: this.dataSecondary.mail,
      name: `${this.creditCard.fullname}`,
      document: this.dataPrincipal.number.toString(),
      number: this.creditCard.number_credit.toString(),
      expMonth: this.creditCard.expiration.split('/')[0],
      expYear: `20${this.creditCard.expiration.split('/')[1]}`,
      typeCard: this.creditCard.type,
      typeDocument: this.dataPrincipal.type,
      cvv: this.creditCard.cvv.toString(),
      line1: this.dataSecondary.direction,
      postalCode: '+51',
      city: this.ubigeo.distrito,
      state: this.ubigeo.distrito,
      country: 'PE',
      phone: this.dataSecondary.phone,
      quantity: '1',
    };

    let data = this.dataAfiliados;

    let infoPersona: AseguradoFinal = {
      user_id: this.dataPrincipal.id, //será 0 cuando no existe el asegurado en bd
      user_tipoDoc_id: parseInt(this.dataPrincipal.type), //2:dni, 1:carnet extrangería
      user_numDoc: this.dataPrincipal.number.toString(),
      user_nom1: this.dataPrincipal.first_name,
      user_nom2: this.dataPrincipal.seconds_name,
      user_ape1: this.dataPrincipal.first_lastname,
      user_ape2: this.dataPrincipal.second_lastname,
      user_direcc: this.dataSecondary.direction,
      user_telf: this.dataSecondary.phone,
      user_email: this.dataSecondary.mail,
      user_ubg: this.ubigeo_oficial,
      user_fech_nac: '', //mantenerlo vacio  si no tiene data
      user_sexo: '', //mantenerlo vacio si no tiene data
      user_tipo: '1', // 1: contratante, 2: afiliado
      user_plan: 254, // PLANES-> 255:TELESALUD OH , 256: TELESALUD FULL , 254:PRUEBAS ANTIGENAS OH
      user_idproveedor: this.dataSecondary.atention_center, //mantener en 0  si no tiene data
      user_parent: 1,
    };

    this.paymentService.sendDataCovid(pagos, data, infoPersona).subscribe(
      (datos) => {
        if (datos) {
          this.dialogService.close();
          this.dialogService.material('thanks', 'seguros');
        } else {
          this.dialogService.close();
          this.dialogService.material('error', 'seguros');
        }
      },
      (error) => {
        this.dialogService.close();
        this.dialogService.material('error', 'seguros');
      }
    );
  }

  back() {
    window.history.back();
  }

  validarDNI() {
    return this.utilService
      .listUser(this.dataPrincipal.number.toString())
      .subscribe(
        (datos) => {
          if (datos != null) {
            this.dataPrincipal.id = datos['user_id'];
            this.dataPrincipal.first_name = new TitleCasePipe().transform(
              datos['user_nom1']
            );
            this.dataPrincipal.seconds_name = new TitleCasePipe().transform(
              datos['user_nom2']
            );
            this.dataPrincipal.first_lastname = new TitleCasePipe().transform(
              datos['user_ape1']
            );
            this.dataPrincipal.second_lastname = new TitleCasePipe().transform(
              datos['user_ape2']
            );
            this.dataSecondary.mail = datos['user_email'].toLowerCase();
            this.dataSecondary.phone = new PhonePipe().transform(
              datos['user_telf']
            );
            this.dataSecondary.direction = new TitleCasePipe().transform(
              datos['user_direcc']
            );
            this.dataSecondary.nacimiento = new DateCasePipePipe().transform(
              datos['user_fech_nac']
            );
            this.validarUbigeo(this.dataPrincipal.number.toString());
            this.ubigeo_oficial = datos['user_ubg'];
            this.searchType = false;
          } else {
            this.searchType = false;
          }
        },
        (error) => {
          this.searchType = false;
        }
      );
  }

  validarUbigeo(dni: string) {
    try {
      return this.utilService.listUbigeo(dni).subscribe(
        (datos) => {
          if (datos != null) {
            this.ubigeo.departamento = datos['departamento'];
            this.ubigeo.provincia = datos['provincia'];
            this.ubigeo.distrito = datos['distrito'];
            this.dataSecondary.general = `${this.ubigeo.departamento} / ${this.ubigeo.provincia} / ${this.ubigeo.distrito}`;
            this.ubigeo.id = datos['id'];
          }
        },
        (error) => {}
      );
    } catch (e) {}
  }

  selectUbigeo() {
    const dialogRef = this.dialog.open(ModalUbigeoComponent, {
      width: '65%',
      data: this.ubigeo,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.ubigeo = result;
      this.dataSecondary.general = `${this.ubigeo.departamento} / ${this.ubigeo.provincia} / ${this.ubigeo.distrito}`;
      this.ubigeo.id = result['id'];
      this.ubigeo_oficial = result['id'];
    });
  }

  firstValidator(): Boolean {
    let value =
      parseInt(this.dataPrincipal.type) > 0 &&
      `${this.dataPrincipal.number}`.length > 6 &&
      this.dataPrincipal.first_name.length > 0 &&
      this.dataPrincipal.first_lastname.length > 0 &&
      this.dataPrincipal.second_lastname.length > 0;
    return value;
  }

  secondValidator(): Boolean {
    let value =
      this.dataSecondary.mail.includes('@') &&
      this.dataSecondary.mail.includes('.') &&
      `${this.dataSecondary.phone}`.length > 6 &&
      this.dataSecondary.direction.length > 3 &&
      this.dataSecondary.atention_center !== null &&
      (this.addQuestion === 1 || this.addQuestion === 0);
    return value;
  }

  thirdValidator(): Boolean {
    let value;
    if (this.dataAfiliados.length > 0 && this.datoInicial > 0) {
      value = this.dataAfiliados.length + 1 > this.step;
    } else {
      value = true;
    }
    return !value;
  }

  fourValidator(): Boolean {
    let value =
      this.poliza &&
      `${this.creditCard.number_credit}`.length > 14 &&
      this.creditCard.type.length > 3 &&
      this.creditCard.fullname.length > 4 &&
      this.creditCard.expiration.length === 5 &&
      `${this.creditCard.cvv}`.length > 2 &&
      this.creditCard.cvv !== null &&
      this.envio &&
      this.poliza;
    return value;
  }

  addPerson(i: number): void {
    this.addQuestion = i;
    if (i === 1) {
      this.dataAfiliados[0] = {
        id: 0,
        user_id: 1,
        user_numDoc: this.dataPrincipal.number.toString(),
        user_tipo_doc: this.dataPrincipal.type,
        user_nom1: this.dataPrincipal.first_name,
        user_ape1: this.dataPrincipal.first_lastname,
        user_ape2: this.dataPrincipal.second_lastname,
        user_fech_nac: this.dataSecondary.nacimiento,
        user_email: this.dataSecondary.mail,
        user_plan: this.validatorPlan,
      };
    }
    if (this.dataAfiliados.length === 0) {
      this.datoInicial = 1;
      this.dataAfiliados.push({
        //ojo aqui
        id: 0,
        user_id: 1,
        user_numDoc: this.dataPrincipal.number.toString(),
        user_tipo_doc: this.dataPrincipal.type,
        user_nom1: this.dataPrincipal.first_name,
        user_ape1: this.dataPrincipal.first_lastname,
        user_ape2: this.dataPrincipal.second_lastname,
        user_fech_nac: this.dataSecondary.nacimiento,
        user_email: this.dataSecondary.mail,
        user_plan: this.validatorPlan,
      });
    }
  }

  onChange(text: string): void {
    switch (this.dataPrincipal.type) {
      case '2':
        this.maxLengthType = 8;
        if (
          this.dataPrincipal.number !== null &&
          this.dataPrincipal.number.toString().length === 8
        ) {
          this.searchType = true;
          this.validarDNI();
        }
        break;
      case '4' || '3':
        this.maxLengthType = 12;
        if (
          this.dataPrincipal.number !== null &&
          this.dataPrincipal.number.toString().length === 12
        ) {
          this.searchType = true;
          this.validarDNI();
        }
        break;
      case '1':
        this.maxLengthType = 20;
        if (
          this.dataPrincipal.number !== null &&
          (this.dataPrincipal.number.toString().length === 11 ||
            this.dataPrincipal.number.toString().length === 20)
        ) {
          this.searchType = true;
          this.validarDNI();
        }
        break;
      case '5':
        this.maxLengthType = 10;
        if (
          this.dataPrincipal.number !== null &&
          this.dataPrincipal.number.toString().length === 10
        ) {
          this.searchType = true;
          this.validarDNI();
        }
        break;
    }
  }

  onChangeSelect(text: string): void {
    // console.log(this.dataSecondary.atention_center);
  }

  onChangeButton(data: Boolean): void {
    this.validatorPlan = 254;
    this.showCenter = data;
  }

  addAfiliado(): void {
    this.dataAfiliados.push({
      id: 0,
      user_id: this.dataAfiliados.length + 1,
      user_numDoc: null,
      user_tipo_doc: '',
      user_nom1: '',
      user_ape1: '',
      user_ape2: '',
      user_fech_nac: '',
      user_email: '',
      user_plan: this.validatorPlan,
    });

    this.datoInicial = this.dataAfiliados.length;
  }

  resultAfiliacion(data: AseguradoModel): void {
    this.step++;
  }

  cancelAfiliacion(i: number): void {
    this.setStep(i - 1);
  }

  editAfiliacion(i: number): void {
    this.setStep(i);
  }

  deleteAfiliacion(id: number): void {
    this.dataAfiliados.splice(id - 1, 1);
    this.datoInicial = this.dataAfiliados.length;
    for (let i = 0; i < this.dataAfiliados.length; i++) {
      this.dataAfiliados[i].user_id = i + 1;
    }
    this.setStep(1);
  }

  getTypeCreditCard(): void {
    this.creditCard.type = new ValidateCardPipe().transform(
      this.creditCard.number_credit.toString()
    );
  }

  validateMMYY() {
    if (this.creditCard.expiration.length == 2) {
      this.creditCard.expiration += '/';
    }
  }

  setStep(index: number): void {
    this.step = index;
  }

  changeCheckValue(condicion: string) {
    // console.log('la condicion es ', condicion);
    switch (condicion) {
      case 'envio':
        this.envio = !this.envio;
        break;
      case 'datos':
        this.datos = !this.datos;
        break;
      case 'poliza':
        this.poliza = !this.poliza;
        break;
    }
  }

  conditions(condicion: string) {
    this.dialogService.material(`${condicion}`, 'planes');
  }
}