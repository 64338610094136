import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Pipe({
  name: 'validateCard',
})
export class ValidateCardPipe implements PipeTransform {
  transform(key: string): string {
    const regExVisa = new RegExp('^4[0-9]{6,}$');
    const regExMastercard = new RegExp(
      '^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$”'
    );
    const regExAmerican = new RegExp('^[34|37][0-9]{14}$');
    const regExDinners = new RegExp(
      '(^30[0-5][0-9]{11}$)|(^(36|38)[0-9]{12}$)'
    );

    if (regExVisa.test(key)) {
      return 'VISA';
    } else if (regExMastercard.test(key)) {
      return 'MASTERCARD';
      // return 'MC';
    } else if (regExAmerican.test(key)) {
      return 'AMEX';
    } else if (regExDinners.test(key)) {
      return 'DINNERS';
      // return 'DC';
    }
  }
}

