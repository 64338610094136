<form class="column" [formGroup]="pacienteFormGroup">
  <mat-label>Tipo de documento</mat-label>
  <mat-form-field
    class="input"
    appearance="outline"
    [(ngModel)]="data.user_tipo_doc"
    (ngModelChange)="onChange('select', $event)"
    [ngModelOptions]="{ standalone: true }"
    ngDefaultControl
  >
    <select formControlName="type" matNativeControl>
      <option value="" [selected]="'' === data.user_tipo_doc">
        Seleccione
      </option>
      <option value="2" [selected]="'2' === data.user_tipo_doc">DNI</option>
      <option value="4" [selected]="'4' === data.user_tipo_doc">
        Pasaporte
      </option>
      <option value="1" [selected]="'1' === data.user_tipo_doc">RUC</option>
      <option value="3" [selected]="'3' === data.user_tipo_doc">
        Carnet de extranjería
      </option>
      <option value="5" [selected]="'5' === data.user_tipo_doc">
        Cédula de identificación
      </option>
    </select>

    <mat-error *ngIf="pacienteFormGroup.controls.type.hasError('required')"
      >Por favor completa este campo</mat-error
    >
  </mat-form-field>

  <mat-label>Número de documento</mat-label>
  <mat-form-field appearance="outline">
    <input
      matInput
      maxlength="{{maxLength}"
      type="tel"
      (ngModelChange)="onChange('', $event)"
      [(ngModel)]="data.user_numDoc"
      formControlName="number"
      required
    />

    <div class="search" *ngIf="searchType" matSuffix>
      <div class="lds-dual-ring"></div>
    </div>

    <mat-error *ngIf="pacienteFormGroup.controls.number.hasError('required')"
      >Por favor completa este campo</mat-error
    >
  </mat-form-field>

  <mat-label>Nombres</mat-label>
  <mat-form-field appearance="outline">
    <input
      matInput
      type="text"
      [ngModel]="data.user_nom1 | titlecase"
      (ngModelChange)="data.user_nom1 = $event"
      formControlName="name"
      required
    />
    <mat-error *ngIf="pacienteFormGroup.controls.name.hasError('required')"
      >Por favor completa este campo</mat-error
    >
  </mat-form-field>

  <mat-label>Apellido Paterno</mat-label>
  <mat-form-field appearance="outline">
    <input
      matInput
      type="text"
      [ngModel]="data.user_ape1 | titlecase"
      (ngModelChange)="data.user_ape1 = $event"
      formControlName="first_lastname"
      required
    />
    <mat-error
      *ngIf="pacienteFormGroup.controls.first_lastname.hasError('required')"
      >Por favor completa este campo</mat-error
    >
  </mat-form-field>

  <mat-label>Apellido Materno</mat-label>
  <mat-form-field appearance="outline">
    <input
      matInput
      type="text"
      [ngModel]="data.user_ape2 | titlecase"
      (ngModelChange)="data.user_ape2 = $event"
      formControlName="second_lastname"
      required
    />
    <mat-error
      *ngIf="pacienteFormGroup.controls.second_lastname.hasError('required')"
      >Por favor completa este campo</mat-error
    >
  </mat-form-field>

  <mat-label>Fecha de nacimiento</mat-label>
  <mat-form-field appearance="outline" disabled (click)="picker.open()">
    <input
      matInput
      [matDatepicker]="picker"
      (ngModelChange)="onChangeDate($event)"
      [(ngModel)]="vacio"
      formControlName="nacimiento"
      readonly
      required
    />
    <mat-datepicker #picker></mat-datepicker>
    <mat-error
      *ngIf="pacienteFormGroup.controls.nacimiento.hasError('required')"
      >Por favor completa este campo</mat-error
    >
  </mat-form-field>

  <mat-label>Correo electrónico</mat-label>
  <mat-form-field appearance="outline">
    <input
      matInput
      type="email"
      [ngModel]="data.user_email | lowercase"
      (ngModelChange)="data.user_email = $event"
      formControlName="mail"
      required
    />
    <mat-error *ngIf="data.user_email === ''"
      >Por favor completa este campo
    </mat-error>
    <mat-error
      *ngIf="
        !pacienteFormGroup.controls.mail.hasError('required') &&
        pacienteFormGroup.controls.mail.hasError('email')
      "
    >
      Ingresa un correo válido
    </mat-error>
  </mat-form-field>

  <div class="row">
    <p></p>
    <div class="row-simple">
      <button mat-button (click)="cancel()" class="blue-button-text">
        CANCELAR
      </button>
      <button
        mat-raised-button
        color="warn"
        class="blue-button"
        (click)="addPacient()"
        [disabled]="validators() === false"
      >
        GUARDAR
      </button>
    </div>
  </div>
</form>
