import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CovidService {
  constructor() {}

  setItem(i: number): void {
    sessionStorage.setItem('cantPruebas', i.toString());
  }

  getCant(): number {
    return parseInt(sessionStorage.getItem('cantPruebas'));
  }
}
