import { Component, Input } from '@angular/core';

import {
  PreguntasPlanes,
  PreguntasCovid,
} from 'src/app/shared/models/pregunta.model';

@Component({
  selector: 'expanded',
  templateUrl: './expanded.component.html',
  styleUrls: ['./expanded.component.scss'],
})
export class ExpandedComponent {
  @Input() page: string;

  constructor() {
    this.page = '';
  }

  public preguntasPlanes = PreguntasPlanes;
  public preguntasCovid = PreguntasCovid;
}
