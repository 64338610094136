<h4 mat-dialog-title>Seleccionar ubigeo</h4>
<div mat-dialog-content class="container-inputs">
  <mat-form-field appearance="outline" class="input">
    <mat-label>Departamento</mat-label>
    <mat-select
      matNativeControl
      [(ngModel)]="data.departamento"
      (ngModelChange)="listProvincias()"
    >
      <mat-option [value]="item" *ngFor="let item of departamentos">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="input">
    <mat-label>Provincia</mat-label>
    <mat-select
      matNativeControl
      [(ngModel)]="data.provincia"
      (ngModelChange)="listDistritos()"
    >
      <mat-option [value]="item" *ngFor="let item of provincias">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="input">
    <mat-label>Distrito</mat-label>
    <mat-select
      matNativeControl
      [(ngModel)]="data.distrito"
      (ngModelChange)="setUbigeo()"
    >
      <mat-option [value]="item" *ngFor="let item of distritos">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions class="actions">
  <button class="save" mat-button [mat-dialog-close]="data" cdkFocusInitial>
    ACEPTAR
  </button>
</div>
