interface Pregunta {
  pregunta: string;
  respuesta: string;
}

export const PreguntasPlanes: Pregunta[] = [
  {
    pregunta: '¿Qué es un plan de salud y por qué lo necesito??',
    respuesta:
      'Es una asistencia que te brinda atenciones médicas online y de forma presencial en nuestros centros médicos a nivel nacional. Empieza a cuidar tu salud y la de tu familia a través de la prevención y control de enfermedades.',
  },
  {
    pregunta: '¿Puedo agregar a mi familia al plan de salud?',
    respuesta:
      'Este beneficio permite proteger a tu cónyuge e hijos, hermanos, padres, tíos y cualquier integrante de la familia para recibir tratamientos y atenciones médicas. Ellos también acceden al asesoramiento online, videollamadas, envío de videos y fotografías.',
  },
  {
    pregunta: '¿Qué es copago?',
    respuesta:
      'Es el monto en soles que asume el afiliado por cada atención médica en la red médica nacional.',
  },
  {
    pregunta: '¿Qué es coaseguro?',
    respuesta:
      'Es un porcentaje a cargo de la EMPRESA, el cual se aplica al monto total de los gastos médicos, la diferencia lo asume el AFILIADO.',
  },
  {
    pregunta: '¿Cuál es la edad de ingreso?',
    respuesta:
      'Podrán incluirse en este plan de salud las personas naturales entre los cero (0) años hasta los sesenta y cuatro (64) años con trescientos sesenta y cuatro (364) días, pudiendo permanecer como Afiliado hasta la edad de sesenta y nueve (69) años y trescientos sesenta y cuatro (364) días.',
  },
  {
    pregunta: '¿Qué no cubre al plan de salud?',
    respuesta:
      'El plan no cubre maternidad, control prenatal, enfermedades preexistentes, crónicas Y controles de rutina.',
  },
  {
    pregunta: '¿El plan de salud cubre enfermedades preexistentes?',
    respuesta: 'No, el plan de salud no cubre ninguna enfermedad preexistente.',
  },
  {
    pregunta: '¿El plan de salud cubre enfermedades congénitas?',
    respuesta:
      'No, el plan de salud no cubre ninguna enfermedad congénita o adquiridas al nacer.',
  },
  {
    pregunta: '¿Cómo coordino mi cita médica?',
    respuesta:
      'Comunícate a nuestra central telefónica: desde Lima (01)445-3019 opción 2 o mediante whatsapp al 947186478 También puedes coordinar tu cita médica online ingresando a nuestra web www.red-salud.com',
  },
];

export const PreguntasCovid: Pregunta[] = [
  {
    pregunta: '¿Cómo funciona?',
    respuesta:
      'La prueba antígeno detecta proteínas presentes en el virus.',
  },
  {
    pregunta: '¿Cómo se obtiene?',
    respuesta:
      'Hisopado Nasofaríngeo.',
  },
  {
    pregunta: '¿Qué mide?',
    respuesta:
      'Infección actual o reciente.',
  },
  {
    pregunta: '¿Quién se la debe de realizar?',
    respuesta:
      'Casos sintomáticos.',
  },
  {
    pregunta: '¿Cuándo se debe realizar?',
    respuesta:
      'En los 7 primeros días tras exposición o contacto y si presenta síntomas.',
  },
  {
    pregunta: '¿Cómo coordino mi prueba Covid-19?',
    respuesta:
      'Nos comunicaremos contigo un máximo de 24 horas para coordinar tu prueba en nuestros laboratorios o a domicilio. En caso de no recibir nuestra llamada, comunícate con nosotros mediante whatsapp al 947186478 o escribiéndonos al correo afiliaciones1@red-salud.com',
  },
];
