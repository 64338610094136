import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Ubigeo } from '../../models/user.model';
import { UtilService } from '../../services/utils/utils.service';
@Component({
  selector: 'app-modal-ubigeo',
  templateUrl: './modal-ubigeo.component.html',
  styleUrls: ['./modal-ubigeo.component.scss'],
})
export class ModalUbigeoComponent implements OnInit {
  public dataDep: any = [];
  public dataProv: any = [];
  public dataDist: any = [];

  public iddepartamento: string = '';
  public idprovincia: string = '';

  public departamentos: string[] = [];
  public provincias: string[] = [];
  public distritos: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalUbigeoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Ubigeo,
    public service: UtilService
  ) {}

  ngOnInit() {
    this.listDepartamentos();
    this.data = {
      id: '',
      departamento: '',
      provincia: '',
      distrito: '',
    };
  }

  listDepartamentos() {
    return this.service.listDepartamentos().subscribe((datos) => {
      this.dataDep = datos;
      for (let item of this.dataDep) {
        this.departamentos.push(item['descripcion']);
      }
    });
  }

  listProvincias() {
    for (let item of this.dataDep) {
      if (this.data.departamento == item['descripcion']) {
        this.iddepartamento = item['iddepartamento'];
      }
    }

    return this.service
      .listProvincias(this.iddepartamento)
      .subscribe((datos) => {
        this.dataProv = datos;
        for (let item of this.dataProv) {
          this.provincias.push(item['descripcion_ubig']);
        }
      });
  }

  listDistritos() {
    for (let item of this.dataProv) {
      if (this.data.provincia == item['descripcion_ubig']) {
        this.idprovincia = item['idprovincia'];
      }
    }

    return this.service.listDistritos(this.idprovincia).subscribe((datos) => {
      this.dataDist = datos;
      for (let item of this.dataDist) {
        this.distritos.push(item['descripcion_ubig']);
      }
    });
  }

  setUbigeo() {
    for (let item of this.dataDist) {
      if (this.data.distrito == item['descripcion_ubig']) {
        this.data.id = item['iddistrito'];
      }
    }
  }
}
