<mat-radio-group id="plan">
  <mat-radio-button value="plan-individual">
    <div class="text">
      <div class="image">
        <h3>Solo a mí</h3>
        <img src="/assets/icons/oh-logo.svg" alt="" />
      </div>
      <img src="/assets/icons/individual.svg" alt="" />
    </div>
  </mat-radio-button>

  <mat-radio-button value="plan-familiar">
    <div class="text">
      <div class="image">
        <h3>A mí y a otras personas</h3>
        <img src="/assets/icons/oh-logo.svg" alt="" />
      </div>
      <img src="/assets/icons/familia.svg" alt="" />
    </div>
  </mat-radio-button>

  <mat-radio-button value="plan-otros">
    <div class="text">
      <div class="image">
        <h3>A otras personas</h3>
        <img src="/assets/icons/oh-logo.svg" alt="" />
      </div>
      <img src="/assets/icons/otros.svg" alt="" />
    </div>
  </mat-radio-button>
</mat-radio-group>
