<form [formGroup]="formGroup">
  <h2 mat-dialog-title>Editar afiliado</h2>
  <div mat-dialog-content>
    <div class="form-group">
      <div class="form-group-child">
        <mat-label>Número de documento</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_numDoc"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_numDoc"
            matInput
            type="number"
            maxlength="8"
            oninput=" if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
            required
            formControlName="dni"
          />
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Teléfono</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_telf"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_telf"
            matInput
            required
            formControlName="telefono"
          />
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Correo</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_email"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_email"
            matInput
            type="email"
            required
            formControlName="email"
          />
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Fecha de nacimiento</mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="afiliado.user_fech_nac"
            [ngModelOptions]="{ standalone: true }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group-child">
        <mat-label>Primer nombre</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_nom1"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_nom1"
            matInput
            type="text"
            required
            formControlName="nomb1"
          />
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Segundo nombre</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_nom2"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_nom2"
            [ngModelOptions]="{ standalone: true }"
            matInput
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Apellido paterno</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_ape1"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_ape1"
            matInput
            type="text"
            required
            formControlName="ape1"
          />
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Apellido materno</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_ape2"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_ape2"
            matInput
            type="text"
            required
            formControlName="ape2"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group-child">
        <mat-label>Dirección</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_direcc"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_direcc"
            matInput
            type="text"
            required
            formControlName="direccion"
          />
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Locación</mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            (click)="modal()"
            [(ngModel)]="ubigeoString"
            formControlName="ubigeo"
            readonly
            required
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="actions">
    <button class="cancel" mat-button (click)="close()">Cancelar</button>
    <button class="save" mat-button type="submit" (click)="aea()">
      Guardar
    </button>
  </div>
</form>
