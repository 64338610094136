import { AseguradoModel } from './../../models/asegurado-model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TitleCasePipe } from '../../pipes/titleCase/title-case.pipe';
import { UtilService } from '../../services/utils/utils.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateCasePipePipe } from '../../pipes/dateCasePipe/date-case-pipe.pipe';

@Component({
  selector: 'app-form-add-pacient',
  templateUrl: './form-add-pacient.component.html',
  styleUrls: ['./form-add-pacient.component.scss'],
})
export class FormAddPacientComponent implements OnInit {
  @Input() initial: AseguradoModel;
  @Output() resultado: EventEmitter<AseguradoModel> = new EventEmitter();
  @Output() back: EventEmitter<number> = new EventEmitter();

  public searchType: boolean = false;
  public vacio: any;
  public data: AseguradoModel = {
    id: 0,
    user_id: null,
    user_numDoc: null,
    user_tipo_doc: '',
    user_nom1: '',
    user_ape1: '',
    user_ape2: '',
    user_fech_nac: '',
    user_email: '',
    user_plan: null,
  };

  public maxLength: number = 20;
  public validator: Boolean = false;
  public pacienteFormGroup: FormGroup;

  constructor(
    private utilService: UtilService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    try {
      this.data = this.initial;
      this.onChange('select', this.data.user_tipo_doc);

      this.pacienteFormGroup = this.formBuilder.group({
        type: [''],
        number: ['', Validators.required],
        name: ['', Validators.required],
        first_lastname: ['', Validators.required],
        second_lastname: ['', Validators.required],
        nacimiento: ['', Validators.required],
        mail: ['', Validators.required],
      });

      let fecha = this.data.user_fech_nac.split('/');

      let dia =
        fecha[0].substr(0, 1) == '0'
          ? fecha[0].substr(1, 1)
          : fecha[0].substr(0, 2);
      let mes =
        fecha[1].substr(0, 1) == '0'
          ? fecha[1].substr(1, 1)
          : fecha[1].substr(0, 2);

      this.vacio = new Date(`${fecha[2]}/${mes}/${dia}`);
    } catch (e) {}
  }

  addPacient(): void {
    if (this.data.user_email.includes('@')) {
      this.resultado.emit(this.data);
    }
  }

  onChangeDate(date: Date) {
    try {
      let t = date.toLocaleDateString().split('/');

      let dia = t[0].length === 1 ? `0${t[0]}` : `${t[0]}`;
      let mes = t[1].length === 1 ? `0${t[1]}` : `${t[1]}`;
      let anio = t[2];

      this.data.user_fech_nac = `${anio}${mes}${dia}`;
    } catch (e) {}
  }

  onChange(condition: string, text: string) {
    if (condition === 'select') {
      this.data.user_tipo_doc = text;
      switch (this.data.user_tipo_doc) {
        case '2':
          this.maxLength = 8;
          break;
        case '4' || '3':
          this.maxLength = 12;
          break;
        case '1':
          this.maxLength = 20;
          break;
        case '5':
          this.maxLength = 10;
          break;
      }
    } else {
      if (
        text != null &&
        (text.length === this.maxLength ||
          (this.data.user_tipo_doc === '1' && text.length === 11))
      ) {
        this.searchType = true;
        return this.utilService.listUser(text).subscribe(
          (datos) => {
            if (datos != null) {
              this.data.id = datos['user_id'];
              this.data.user_nom1 = new TitleCasePipe().transform(
                datos['user_nom1']
              );
              this.data.user_ape1 = new TitleCasePipe().transform(
                datos['user_ape1']
              );
              this.data.user_ape2 = new TitleCasePipe().transform(
                datos['user_ape2']
              );
              this.data.user_email = datos['user_email'].toLowerCase();
              this.data.user_fech_nac = new DateCasePipePipe().transform(
                datos['user_fech_nac']
              );
              this.vacio = new Date(
                `${this.data.user_fech_nac.replace('/', '-')}`
              );
            }
            this.searchType = false;
          },
          (error) => {
            this.searchType = false;
          }
        );
      }
    }
  }

  validators(): Boolean {
    let value = (this.validator =
      `${this.data.user_numDoc}`.length > 6 &&
      this.data.user_nom1.length > 0 &&
      this.data.user_ape1.length > 0 &&
      this.data.user_ape2.length > 0 &&
      this.data.user_fech_nac !== '' &&
      this.data.user_email.includes('@') &&
      this.data.user_email.includes('.'));
    return value;
  }

  cancel(): void {
    this.back.emit(this.initial.user_id);
  }
}